.content {
  text-align: center;
  margin: 0 auto;
  max-width: 960px;

  > .title {
    margin-top: 80px;
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 48px;
    color: #725cff;
    letter-spacing: 2px;
    font-style: normal;
    text-transform: none;
    line-height: 1.1;

    @media screen and (max-width: 768px) {
      margin-top: 50px;
      font-size: 36px;
    }
  }

  > .subTitle {
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 48px;
    color: #0c0c0c;
    text-align: center;
    font-style: normal;
    text-transform: none;

    @media screen and (max-width: 768px) {
      margin-top: 10px;
      font-size: 18px;
    }
  }

  > .subDesc {
    margin-top: 20px;
    font-family: Inter, Inter;
    font-weight: 500;
    font-size: 24px;
    color: rgba(12, 12, 12, 0.6);
    // line-height: 22px;
    font-style: normal;
    text-transform: none;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .list {
    margin-top: 36px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    justify-content: space-evenly;
    grid-gap: 10px;

    .listItem {
      display: flex;
      align-items: center;
      justify-content: center;

      > :first-child {
        margin-right: 5px;
      }
    }

    @media screen and (max-width: 768px) {
      // grid-template-columns: repeat(2, 1fr);
      display: flex;
      align-items: center;
      font-size: 14px;
      grid-gap: 0px;
    }
  }

  .inputContainer {
    display: inline-flex;
    align-items: center;
    height: 54px;
    // background: #eeebff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    overflow: hidden;
    gap: 5px;

    @media screen and (max-width: 576px) {
      width: 100%;
    }

    > input {
      // margin-left: 10px;
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      color: var(--app-text-color);
      font-size: 16px;
      margin-left: 5px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus,
      &:active {
        outline: 0;
        box-shadow: none;
      }
    }

    .searchBtn {
      background: linear-gradient(180deg, #725cff 0%, rgba(141, 131, 255, 0.8) 100%);
      color: #fff;
      border-radius: 6px;
      height: 40px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      box-shadow: 0px 4px 12px 0px rgba(114, 92, 255, 0.2);

      &:hover {
        background: linear-gradient(180deg, #725cff 0%, rgba(141, 131, 255, 0.8) 100%);
        color: #fff;
      }
    }

    > span {
      border-radius: 12px;

      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 20px;
      height: 100%;
    }
  }
  .searchBtnWithFullWidth {
    background: linear-gradient(180deg, #725cff 0%, rgba(141, 131, 255, 0.8) 100%);
    color: #fff;
    border-radius: 6px;
    height: 44px;
    width: 520px;
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    box-shadow: 0px 4px 12px 0px rgba(114, 92, 255, 0.2);

    &:hover {
      background: linear-gradient(180deg, #725cff 0%, rgba(141, 131, 255, 0.8) 100%) !important;
      color: #fff !important;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
  .error {
    width: 520px;
    margin: auto;
    text-align: left;
    color: red;
    line-height: 1.1;
    margin-top: 10px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  > .timesDesc {
    max-width: 768px;
    margin: 0 auto;
    margin-top: 24px;
    font-family: Inter, Inter;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    font-style: normal;
    text-transform: none;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: space-around;

    .item {
      display: inline-flex;
      align-items: center;
      gap: 3px;

      justify-content: center;

      > span {
        font-weight: 500;
      }
    }

    @media screen and (max-width: 768px) {
      max-width: 520px;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;

      .item {
        justify-content: flex-start;
      }
    }
  }
}

